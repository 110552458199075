@import '../../../styles/new/variables';

.titleRow {
  background-color: transparent;
  border: none;
  font-weight: 700;
  vertical-align: bottom;

  th {
    padding: 0 .7rem;
    text-align: center;
  }
}

.titleRowApplyAll {
  & td:first-child {
    height: 35px;
    font-weight: 500;
    padding-left: .5rem;
    text-align: left;
  }
}

.titleRowDaysOut {
  input {
    max-width: 60px;
  }
}

.mustCallHeader {
  max-width: 90px;
}

.noWrap {
  white-space: nowrap;
}

.tooltipContent {
  color: $dark-gray;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.4rem;
  text-align: left;
  width: 229px;
}

.tooltipDetails {
  margin-bottom: 16px;
}

.tooltipDetailNote {
  font-weight: 700;
}

.tooltipDetailNoteWarning {
  color: $warning;
  margin: 0 2px 0 0;
}

.mustCallIcon {
  color: $medium-gray;
  cursor: pointer;
  font-size: 1.4rem;
  margin-left: 2px;
  position: relative;
}
