@import '../../../styles/new/variables';

.ticketSitesForm {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.selectSiteDropdown {
  // added a width to this dropdown because Chrome was auto-guessing a width and causing
  // the input to truncate in unexpected ways. FF was being a dude is all I have to say...
  width: 80%;
}

.ticketSitesButton {
  margin: 3.5rem 0 auto auto;
}

.siteItemContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
}

.siteItem {
  border: 1px solid $light-gray;
  border-bottom: none;
  font-weight: 500;
  padding: .75rem;
  display: flex;
  flex-flow: row nowrap;
  flex-basis: 90%;
  justify-content: space-between;
}

.lastSiteItem {
  border-bottom: 1px solid $light-gray;
}

.siteItemName {
  margin-left: .75rem;
  flex: 1;
}

.siteItemCost {
  position: relative;
  color: $medium-gray;
  right: 0;
}

.removeIcon {
  color: $warning;
  font-size: 18px;
}

.removeBtn {
  margin: auto;
}
