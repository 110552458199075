@import '../../styles/new/variables';
@import '../../styles/new/mixins';

.ticketFeeTableContainer {
  padding-top: 2rem;
  margin-top: 2rem;
  border-top: 1px solid $light-gray;
  width: 100%;
  font-size: 14px;
}

.feeType {
  display: flex;
}

.feeTypeIcon {
  font-size: 16px;
  margin-left: 0.5rem;
  position: relative;
  top: 4px;
  transform: scaleX(-1)
}

.ticketTypeResourceType {
  font-weight: 500;
}

.feeTypesTable {
  width: 75%;
}

.feeTypesTableFullWidth {
  width: 100%;
}

.editFeesBtn {
  margin-top: .5rem;
}

.chargeTotals {
  border-top: 1px solid $dark-gray;
  padding-top: .5rem;
}

.lastTicketFee {
  padding-bottom: .5rem;
}

.removeTicketContainer {
  padding: 2rem;
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.removeTicketButtonWrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.removeTicketText {
  font-size: 1.6rem;
  text-align: center;
}
.removeTicketsFooter {
  margin-top: 3rem;
  font-style: italic;
}

.noTicketsMessage {
  background-color: $white !important; // Overwriting bootstrap table striped
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}

.noTicketsMessageCell {
  padding-top: 2rem !important; // Overwriting bootstrap td styling
}