@import '../../styles/new/variables';

.discountTaxFormContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 1rem 0 2rem 0;
}

.discountTaxFormContainerNoMargin {
  margin: 0;
}

.discountAndTaxInput {
  width: 25%;
  margin: 0 .5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.qboTaxInput {
  margin-top: 30px;
}

.taxCodeToolTip {
  color: $medium-gray;
  font-size: 14px;
}

.taxCodeToolTipNonAST {
  margin-left: .5rem;
  position: relative;
  top: .2rem;
}

.taxCodeToolTipAST {
  margin: auto .5rem;
  position: relative;
  top: .3rem;
}

.discountTaxFormTitle {
  text-transform: capitalize;
  margin: 1.25rem 0 0 1rem;
}

.discountPercent {
  margin: .75rem 1rem 0 0;
}

.removeDiscountTaxIcon {
  color: $warning;
  margin-top: 1.5rem;
  cursor: pointer;
  font-size: 18px;
}

.taxCodeSelect {
  margin-right: 2rem;
}

.discountTitleQB, .taxCodeSelect {
  width: 50%;
}

.qboRemoveTax {
  margin-top: 2rem;
  margin-right: 1rem;
}

.disabledTax:disabled {
  border-bottom: none !important;
}

.errorText {
  color: $warning;
}

.astToolTipContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.astToolTipContainer {
  margin-right: auto;
}

.feesInput {
  & input {
    &:invalid {
      color: $warning;
      box-shadow: inset 0 -2px 0 rgba($warning, .2);
    }
    &:focus:invalid {
      box-shadow: inset 0 -2px 0 $warning;
    }
  }
}