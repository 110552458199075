@import '../../../styles/new/variables';

@mixin top-position {
  top: 150%;
}

@mixin top-arrow {
  top: -10px;
  right: 0;
  transform: translateX(-50%);
}

.tooltip {
  position: relative;
}

.wrapper {
  position: absolute;
  z-index: 1000;
  width: auto;
  height: auto;
  background: $primary-lt;
  box-shadow: 0 1px 4px $medium-gray;
  padding: 10px;
}

.top {
  &Right {
    @include top-position;
    right: -45px;
  }

  &Left {
    @include top-position;
    left: -45px;
  }

  &Center {
    @include top-position;
    left: auto;
    right: auto;
  }
}

.arrow {
  height: 10px;
  overflow: hidden;
  position: absolute;
  width: 50px;

  &::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background: $primary-lt;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    top: 100%;
    left: 50%;
    box-shadow: 0 1px 4px $medium-gray;
  }
}

.arrowTop {
  &Right {
    @include top-arrow;
    right: 0;
  }

  &Left {
    @include top-arrow;
    left: 0;
  }

  &Center {
    @include top-arrow;
    left: 45%;
  }
}
