@import '../../styles/new/variables';
@import '../../styles/new/mixins';

.mainModalContainer{
  width: 90%;
  position: relative;
  top: -15%;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.modalWrapper {
  background-color: $white;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .7);
  color: $dark-gray;
  font-size: 1.4rem;
  padding: 2rem;
  width: 100%;
  > p {
    margin: 2rem 0;
  }
}

.closeModalBtn {
  position: relative;
  display: block !important;
  font-size: 16px;
  top: -10px;
}

.closeModalIcon {
  font-size: 18px;
  position: relative;
  top: 4px;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    margin: 0;
    padding: 0;
  }
  margin-bottom: 1rem;
  span {
    position: relative;
    margin-left: 1rem;
    i {
      position: absolute;
      top: -2px;
    }
  }
}

@include respond-to('lg') {
  .mainModalContainer {
    width: 35%;
  }
}