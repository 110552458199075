@import '../../styles/new/variables';
@import '../../styles/new/mixins';

.wrapper {
  display: flex;
  justify-content: space-between;
}

.qbBenefits {
  width: 55vw;
}

.wpad {
  align-items: center;
  background-color: $primary-lt;
  border-radius: .25em;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1.5rem 1rem;
  width: 25vw;
  max-width: 250px;
}

.ad {
  font-size: 18px;
  font-weight: 400;
  line-height: .1;
  margin: 1em 0 .8em;
}

.wpad h6 {
  background-color: $primary;
  border-radius: 50px;
  margin: 0;
  padding: .2rem .6rem;
  font-weight: 500;
  font-size: 12px;
}

.wpad p {
  text-align: center;
  line-height: 1.2em;
}

.wpad a {
  color: $link-blue;
}

.settingsSection {
  padding: 2rem;
  border-radius: 0.25rem;
  border: 1px solid $light-gray;
  background-color: $white;
  margin-top: 2rem;
}

.settingsSectionTitle {
  font-size: 24px;
  margin: 0; // overwriting bootstrap header styles
}

.settingsCheckbox {
  position: relative;
  bottom: 0.2rem;
}

.invoiceSettingsLabel {
  font-weight: 500;
  margin: 1rem 0 0 .25rem !important; // overwriting bootstrap label
}

.invoiceSettingsLabelText {
  font-size: 18px;
}

.invoiceSettingsMessage {
  font-size: 14px;
}

.subtitle {
  font-size: 1.5rem;
  font-weight: bold;
}

.titledSection {
  margin: 2rem 0 0 0;
}

.radio {
  display: block;
}
