@import '../../styles/new/mixins';

.formContainer {
  display: flex;
  flex-direction: column
}

.formRow, .buttonRow {
 flex-wrap: wrap;
 display: flex;
 flex-direction: row;
}

.buttonRow {
  margin: 2rem 0;
  justify-content: space-between;
}

.clearBtn {
  margin-right: 1rem;
}

.quarterWidthLabel, .halfWidthLabel {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
}

.btnGroup {
  > ul {
    min-width: 120px;
  }
}


@include respond-to('lg') {
  .quarterWidthLabel {
    flex-basis: 25%;
    margin: 0 2rem 1rem;
  }

  .halfWidthLabel {
    flex-basis: 50%;
    margin: 0 2rem 1rem;
  }

  .formRow {
    flex-wrap: nowrap;
  }
}