@import '../../styles/new/variables';
@import '../../styles/new/mixins';

.layoutContainer {
  margin-right: auto;
  margin-left: auto;
}

.ticketContainer {
  margin-top: 3.5rem;
}

.pageTitle {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.statusContainer {
  margin-left: auto;
  margin-top: 2.5rem;
}

.statusLabel {
  margin-left: 1rem;
}

.ticketDisplayContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.containerLeft {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.containerRight {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.destroyTicketContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

@include respond-to('lg') {
  .ticketContainer {
    margin: 3.5rem -1.25rem 0 -1.25rem;
  }

  .statusContainer {
    margin-right: -15px;
  }

  .containerLeft {
    width: 67%;
  }

  .containerRight {
    width: 30%;
    margin-left: auto;
  }
}
