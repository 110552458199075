@import '../../../styles/new/variables';

.noImages {
  color: $med-dk-gray;
  text-align: center;
  font-size: 16px;
}

.addImageButton {
  margin: 1rem auto 1.5rem auto;
}

.imagesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ticketImagesPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ticketImagePanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px;
  margin: 1rem;
  padding-bottom: 1rem;
  flex-basis: 22%;
  &.ticketArchivedPanel {
    padding: 1rem;
  }
}

.ticketImageWrapper {
  height: 150px;
  margin: 3rem .75rem .75rem .75rem;
}

.ticketImage {
  max-height: 100%;
  display: block;
  max-width: 100%;
  height: auto;
  border: none;
}

.buttonContainer {
  margin-bottom: 1.5rem;
}

.lightbox {
  position: fixed;
  z-index: 2000;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.8);
}

.lightboxImg {
  max-width: 90%;
  max-height: 80%;
  margin: 2% auto auto auto;
  z-index: 2001;
}

.deleteImageButton {
  margin-left: 10px;
}

.archivedImageTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
}

.archivedImageInstructions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}
