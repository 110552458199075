.dispatch-tickets-panel {
  display: flex;
  flex-direction: column;
  height: fit-content;
  &_open {
    overflow: hidden;
    transition: max-height 0.6s ease;
  }
  &_closed {
    overflow: hidden;
    transition: max-height 0.6s ease;
    max-height: 0px;
  }
}

.driver-sort {
  width: 425px;
  &_title {
    font-size: 16px;
  }
  &_sort-list {
    max-height: 500px;
  }
  &_sortable-item {
    z-index: 10000;
  }
}

@media (max-width: 1150px) {
  @media (min-width: 992px) {
    .dispatch-custom-id {
      margin-right: 200px !important;
    }
    .dispatch-ticket-assigned {
      margin-left: 20px !important;
    }
  }
}

