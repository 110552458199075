.report {
  height: 100vh;
}

.reportTitle {
  font-size: 3rem;
  margin: 0 0 0 20px;
}

.navigation {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2rem 0;
}

.navigationLeft {
  display: flex;
  align-items: center;
}

.navigationButton {
  font-size: 1.5rem;
}

.backIcon {
  color: #131514;
  font-size: 4rem;
}
