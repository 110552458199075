@import '../../../styles/new/variables';

.jobDetailsResourceList {
  margin: 1rem 0;
}

.jobDetailsResource {
  margin-bottom: .75rem;
  border: 1px solid $light-gray;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.jobDetailsLink {
  margin-right: .5rem;
}

.jobDetailsTime {
  opacity: .5;
  font-style: italic;
}
