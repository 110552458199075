@import '../../styles/new/variables';
@import '../../styles/new/mixins';

.addTicketContainer {
  padding: 2rem;
  width: 40vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.addTicketButtonWrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.addTicketText {
  font-size: 1.6rem;
  
  text-align: center;
}
.addTicketsFooter {
  margin-top: 3rem;
  font-style: italic;
}
.warningIcon {
  color: $alert;
  font-size: 48px;
}