.formRow {
  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: auto;
    & input {
      float: none;
      margin: 0;
    }
    & label {
      margin: 0;
      font-size: 1.5rem;
    }
  }
}

.doNotPromptInput {
  margin-top: 1.5rem;
}