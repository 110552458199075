@import '../../styles/new/variables';

.centeredHeader {
  text-align: center;
}
.lowerContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-content: flex-start;
}
.addTicket {
  padding-left: 0;
}
.addTicketLink {
  color: $dark-gray;
}
.invoiceContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 75%;
  align-items: center;
  padding: 2rem;
}
.invoiceNumber {
  font-weight: 500;
}

