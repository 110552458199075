@import '../../../styles/new/variables';

.hidden {
  display: none;
}

.job-search-form {
  margin-bottom: 2rem;
}

.form-fields {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  :first-child {
    margin-left:0;
  }
  label {
    flex-grow: 1;
    margin-left: 1rem;
    display: block;
    margin-bottom: 0;
    input {
      display: block;
      width: 100%;
      background-color: transparent;
    }
    select {
      display: block;
      background-color: transparent;
      width: 100%;
    }
  }
}