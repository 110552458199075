@import '../../styles/new/mixins';

.inputContainer > div {
  width: 100%;
  &:first-of-type {
    margin-right: 0;
  }
}

.inputContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.inputSectionTitle {
  margin-bottom: .5rem;
}

.buttonContainer {
  margin-top: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.phoneContainer {
  display: flex;
  flex-wrap: nowrap;
  & div:first-child {
    width: 67%;
    margin-right: 2rem;
  }
  & div:last-child {
    width: 33%;
  }
}

.stateZipContainer {
  display: flex;
  flex-wrap: nowrap;
  & div {
    width: 50%;
  }
  & div:first-child {
    margin-right: 2rem;
  }
}

@include respond-to('lg') {
  .inputContainer > div {
    width: 50%;
    &:first-of-type {
      margin-right: 3rem;
    }
  }

  .inputContainer {
    flex-wrap: nowrap;
  }

  .buttonContainer {
    flex-wrap: nowrap;
  }
}

@include respond-to('md') {
  .buttonContainer {
    flex-wrap: wrap;
  }
}