@import './variables';

// This mixin mostly exists to ensure we don't use breakpoints outside of our standardized $breakpoints. It also exists
// to make sure we are always writing CSS mobile first which means we are writing our CSS for mobile devices and then
// using breakpoints to respond to larger screen devices and not the other way around.
@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
