@import '../../../styles/new/variables';

.chargeTotals {
  border-top: 1px solid $dark-gray;
  padding-top: .5rem;
}

.taxTotal {
  margin-bottom: .5rem;
}

.noPaymentsMessage {
  margin-bottom: 1rem;
}

.invoiceConfirmationTotalTable {
  margin-left: auto;
}

.invoiceConfirmationTotals {
  padding: 0 16px !important; // overwriting bootstrap
}

.invoiceConfirmationTax {
  padding: 0 16px 8px 16px !important; //overwriting bootstrap
}

.invoiceConfirmationTotal {
  font-weight: 500;
  padding: 8px 16px 0 16px;
}

.hideDiscountTaxTotals {
  padding: 30px 0 0 0 !important;
}

.totalTable, .tableContainer {
  width: 100%;
}

.qboTxnMessage {
  font-size: 16px;
  color: $medium-gray;
  text-align: center;
  background-color: $white !important; // overwriting bootstrap table striped
}

.transactionTableMessage, .noPaymentsMessage {
  font-style: italic;
}

.qboTxnMessage > td {
  padding-top: 2rem !important; //overwriting bootstrap td styling
}

.printBtnContainer {
  display: block;
}

.printBtnSpinner {
  color: $white;
  margin: auto;
}

.printBtn {
  width: 14rem;
}

.totalRow {
  background-color: $white !important; // overwrites bootstrap table striped
}

.buttonWrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

td a {
  margin: 0 1rem;
}

.transactionError {
  text-align: center;
  color: $warning;
  padding: 4rem 0;
}
