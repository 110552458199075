@import '../../styles/new/variables';

.title {
  color: $dark-gray;
  font-size: 1em;

  &:hover {
    color: $dark-gray;
    text-decoration: none;
  }
}
