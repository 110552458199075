@import '../../styles/new/variables';

.pageWrapper {
  width: 50vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  padding-top: 10vh;
  align-self: center;
  font-size: 1.6rem;
}

.headerText {
  font-size: 3.4rem;
  margin: 0;
  padding: 0;
}

.headerPadding {
  padding: 1.7rem 1.5rem 1.5rem 2.4rem;
}

.logoImg {
  max-width: 150px;
  height: auto;
  display: block;
}

.buttonPadding {
  margin-left:.5rem;
}

.spinner {
  display: inline-block;
}

.summaryRow {
  display: flex;
  justify-content: space-between;
  p {
    margin: 0;
  }
}

.totalAmount {
  text-align: right;
  font-size: 2rem;
  margin-bottom: 0;
}

.pageSection {
  > h3 {
    margin: 0 0 1rem 0;
    font-size: 2.4rem;
    font-weight: $font-weight-normal;
  }
  > h4 {
    font-size: 1.8rem;
    font-weight: $font-weight-bold;
    padding: 3.2rem 0 1.5rem 0;
    margin: 0;
    &.headerInline {
      display: flex;
      align-items: center;
    }
    &.isTop {
      padding-top: 0;
    }
  }
  &.paymentErrorWrapper {
    border: 1px solid $warning;
    background-color: $warning-light;
  }
}

.formRow {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
  label {
    width: 100%;
  }
  .fullWidthInput {
    width: 100%;
  }
  .halfWidthInput {
    width: 45%;
  }
  &.formRowThird {
    div:first-child {
      width: 50%;
    }
    div:nth-child(2) {
      width: 10%;
    }
    div:last-child {
      width: 30%;
    }
  }
}

.submitBtn {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ccImg {
  padding-left: 1rem;
}

.paymentSuccessWrapper,
.paymentErrorWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  > p {
    margin-bottom: 0.5rem;
  }
  > h3 {
    margin-bottom: 1rem;
  }
  .paymentStatusCheck {
    color: $primary;
    margin: 1.5rem 0 3rem 0;
    font-size: 6.5rem;
    &.errorIcon {
      color: $warning;
    }
  }
}

.wpImgWrapper {
  display: flex;
  justify-content: end;
  > img {
    height: auto;
    width: 134px;
  }
}
