.title {
  font-size: 3.75rem;
  margin: 4rem 0 1rem 0;
}

.description {
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin: .5rem 0 1rem 0;
}
