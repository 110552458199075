@import '../../../styles/new/variables';

.fix-request {
  background-color: $light-gray;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .7);
  color: $dark-gray;
  font-size: 1.4rem;
  padding: 2rem;
  width: 50vw;
  h3 {
    font-size: 2.4rem;
    text-align: center;
  }
}

.header-text {
  text-align: center;
  margin-bottom: 3rem;
}

.footer-text {
  text-align: center;
  margin-top: 1rem;
}

.tiny-icon {
  font-size: 20px;
  margin-left: .5rem;
}

.location-selector {
  label {
    display: inline-block;
    margin-right: 3rem;
    cursor: pointer;
  }
}

.address-details-label {
  margin-top: 1rem;
  width: 100%;
  display: block;
}

.button-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2rem;
}

/* button was added for specificity reasons to get this sucker centered*/
button.center-footer-btn {
  display: block;
  margin: 2rem auto 0 auto;
}

.contact-window {
  p {
    margin: 0
  }
}

.window-header {
  font-weight: 700;
  padding-right: 1rem;
  text-transform: capitalize;
}