@import './_variables.scss';
@import './_mixins';

#dis-tooltip {
  z-index:100000;
}

// modal
.dis-modal-wrapper {
  width: 90%;
  position: relative;
  top: -15%;
}
.dis-modal-inner {
  background-color: $light-gray;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .7);
  color: $dark-gray;
  font-size: 1.4rem;
  padding: 2rem;
  width: 50vw;
  h3 {
    font-size: 2.4rem;
    margin: 0 0 1rem 0;
  }
}
.dis-close-modal-icon {
  font-size: 18px;
  position: relative;
  top: 4px;
}

.dis-page-title-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}

// list groups
.dis-list-group {
  padding-left: 0;
  margin-bottom: 20px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  background-color: $white;
}

.dis-list-group-header {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  flex-wrap: nowrap;
  h5 {
    margin: 0;
  }
}

.dis-list-item {
  padding: .75rem 1.5rem;
  margin-bottom: -1px;
  border: 1px solid $light-gray;
  list-style: none;

  &.dis-list-item-thick {
    padding: 1.5rem;
  }

  &:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
}

.dis-list-item-gray {
  background-color: $light-gray;
}

.dis-list-group-header-link {
  margin: auto 0 auto auto;
  font-size: 14px;
}

.dis-list-item-header {
  font-weight: 500;
  font-size: 16px;
}

.dis-list-item-text {
  margin-bottom: 0;
  line-height: 1.3;
  font-size: 16px;
}

// panels
.dis-panel {
  border: none;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  background-color: $white;
  margin-bottom: 2.25rem;
  dl {
    margin-bottom: 1.5rem;
  }
}

.dis-panel-header {
  background-color: $light-gray;
  padding: .75rem 1.5rem;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  h5 {
    margin: 0;
  }
}

.dis-panel-body {
  padding: 1.5rem;
}

// table
.dis-table {
  line-height: 1.6rem;
  width: 100%;
  table-layout: fixed;
  & thead {
    border-bottom: solid 1px $dark-gray; 
  }
  & tbody {
    & tr {
      &:nth-child(even) {
        background-color: $light-gray;
      }
    }
  }
  & td, th {
    padding: 2rem 0;
  }
  &--center {
    & td, th {
      text-align: center;
    }
  }
}

// buttons
.dis-btn {
  align-items: center;
  border: none;
  border-radius: 3px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  display: inline-flex;
  outline: none !important;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.4s;
  white-space: nowrap;
  vertical-align: middle;

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
    opacity: 0.65;
  }

  &:hover {
    text-decoration: none; // Overwrites the rule where all links are underlined.
  }
}

.dis-btn-no-shadow {
  box-shadow: none !important;
}

.dis-btn-sm {
  font-size: 12px;
  padding: 5px 10px;
  line-height: 1.5;
}

.dis-btn-lg {
  font-size: 17px;
  padding: 6px 16px;
  line-height: 1.7;
  font-weight: 400;
}

.dis-btn-xs {
  font-size: 12px;
  padding: 1px 5px;
  line-height: 1.5;
}

.dis-btn-success {
  background-color: $alert;
  color: $white;

  &:hover {
    background-color: darken($alert, 6%);
    color: $white; // Overwrites the rule where all links are blue.
  }
}

.dis-btn-primary {
  background-color: $primary;
  color: $dark-gray;

  &:hover {
    background-color: darken($primary, 6%);
    color: $dark-gray; // Overwrites the rule where all links are blue.
  }
}

.dis-btn-gray {
  background-color: $light-gray;
  color: $dark-gray;

  &:hover {
    background-color: darken($light-gray, 6%);
    color: $dark-gray; // Overwrites the rule where all links are blue.
  }
}

.dis-btn-primary-dk {
  background-color: $primary-dk;
  color: $white;

  &:hover {
    background-color: darken($primary-dk, 6%);
    color: $white; // Overwrites the rule where all links are blue.
  }
}

.dis-btn-primary-lt {
  background-color: $primary-lt;

  &:hover {
    background-color: darken($primary-lt, 6%);
    color: $dark-gray; // Overwrites the rule where all links are blue.
  }
}

.dis-btn-danger {
  background-color: $warning;
  color: $white;

  &:hover {
    background-color: darken($warning, 6%);
    color: $white; // Overwrites the rule where all links are blue.
  }
}

.dis-btn-alert {
 background-color: $alert;
 color: $white;

  &:hover {
    background-color: darken($alert, 6%);
    color: $white; // Overwrites the rule where all links are blue.
  }
}

.dis-btn-white {
  border-radius: 3px;
  color: $dark-gray;
  background-color: $white;

  &:hover {
    background-color: $light-gray;
    color: $dark-gray; // Overwrites the rule where all links are blue.
  }
}

.dis-btn-link {
  border: none;
  box-shadow: none;
  color: $link-blue;
  text-transform: none;
  vertical-align: baseline;

  &:hover {
    text-decoration: underline;
  }

  &-no-hover {
    &:hover {
      text-decoration: none;
    }
  }
}

.dis-btn-blank {
  border: none;
  box-shadow: none;
  color: $med-dk-gray;

  &:hover {
    background-color: $light-gray;
    color: $dark-gray;
  }

  &-dk-bg {
    &:hover{
      background-color: $medium-gray;
      color: $white;
    }
  }
}

.dis-btn-icon {
  margin-left: 10px;
}

// labels
.dis-label {
  border-radius: .25em;
  color: $white;
  display: inline;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  padding: .2em .6em .3em;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.dis-label-pill {
  border-radius: 50px;
}

.dis-label-success {
  background-color: $primary;
}

.dis-label-alert {
  background-color: $alert;
}

.dis-label-warning {
  background-color: $warning;
}

.dis-label-default {
  background-color: $light-gray;
}

.dis-label-default-md {
  background-color: $medium-gray;
}

.dis-label-default-dk {
  background-color: $dark-gray;
}

.dis-flex-page-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

// form page with help section layouts
.dis-form-page-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 15px;
  padding-right: 15px;
}

.dis-form-container-left {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dis-form-container-right {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@include respond-to('lg') {
  .dis-form-page-container {
    margin: 3.5rem -1.25rem 0 -1.25rem;
  }

  .dis-form-container-left {
    width: 58.3333%;
  }

  .dis-form-container-right {
    width: 33.3333%;
    margin-left: 8.3333%;
  }
}

/* Forms! */
.dis-form-label-input-wrapper {
  display: block;
  width: 100%;
  font-size: 1.2rem;
  margin: 0 0 1rem 0;
  & input,
  select {
    &:focus {
      box-shadow: inset 0 -2px 0 $primary;
      outline: 2px solid transparent;
      outline-offset: 2px;
    }
    height: $input-height;
    width: 100%;
    line-height: 1.846;
    color: $dark-gray;
    background-color: transparent;
    /* Keeping this below because they were taken from bootstrap's css and they might be useful for something later idk */
    /* background-image: none; */
    /* border-radius: 3px; */
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    box-shadow: inset 0 -1px 0 $shadow;
    font-size: 16px;
    padding: 0;
    -webkit-appearance: none;
  }
  & select {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAJ1BMVEVmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmaP/QSjAAAADHRSTlMAAgMJC0uWpKa6wMxMdjkoAAAANUlEQVR4AeXJyQEAERAAsNl7Hf3X6xt0QL6JpZWq30pdvdadme+0PMdzvHm8YThHcT1H7K0BtOMDniZhWOgAAAAASUVORK5CYII=);
  }
}

/* this is the class the google maps autopopulate address filler-outer 9000 uses*/
.pac-container {
  z-index: 8000;
}

.dis-disabled-link {
  opacity: .65;
  pointer-events: none;
  box-shadow: none;
}

.btn-group {
  .drop-down {
    position: relative;
    left: -10px;
  }
  .dropdown-menu{
    > li {
      width: 100%;
      a {
        display: flex;
        justify-content: space-between;
        margin: 0;
        width: 100%;
        padding-right: 0;
      }
    }
  }
}

.dis-toggle-switch-wrapper {
  margin: auto 4rem 0 0;
  align-items: center;
}

.dis-toggle-label {
  margin-left: 1rem;
  font-size:  1.6rem;
}
