@import '../../styles/new/variables';

.panel {
  background-color: $primary-lt;
  box-shadow: 0 1px 4px rgba(0,0,0,0.3);
  display: grid;
  grid-template-rows: 0fr 0fr;
  transition: grid-template-rows 500ms;

  &.open {
    background-color: $white;
    border-top: 1px;
    grid-template-rows: max-content 1fr;
  }
}

.title {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 2.4rem;
  font-weight: 100;
  justify-content: space-between;
  padding: .5rem 2rem;
  text-align: left;
}

.icon {
  color: $dark-gray;
  font-size: 3rem;
}

.content {
  overflow: hidden;
}
