@import '../../styles/new/mixins';

.timeInput {
  width: 100%;
  &:first-of-type {
    margin-right: 0;
  }
}

.requestedTimes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.buttonContainer {
  margin-top: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@include respond-to('lg') {
  .timeInput {
    width: 50%;
    &:first-of-type {
      margin-right: 3rem;
    }
  }

  .requestedTimes {
    flex-wrap: nowrap;
  }
}
