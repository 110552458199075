.center__all--large {
  @extend .col-xs-12, .col-sm-12, .col-md-10, .col-md-offset-1, .col-lg-8, .col-lg-offset-2, .text-center;
}

.center__all--medium {
  @extend .col-xs-12, .col-sm-12, .col-md-8, .col-md-offset-2, .col-lg-6, .col-lg-offset-3, .text-center;
}

.center__all--small {
  @extend .col-xs-12, .col-sm-12, .col-md-6, .col-md-offset-3, .col-lg-4, .col-lg-offset-4, .text-center;
}

.spacer__bottom--large {
  padding-bottom: $spacing-large;
}

.spacer__bottom--medium {
  padding-bottom: $spacing-medium;
}

.spacer__bottom--small {
  padding-bottom: $spacing-small;
}

.push-down-large {
  margin-top: 10rem;
}

.push-down-medium {
  margin-top: 7.5rem;
}

.push-down-small {
  margin-top: 5rem;
}

.push-down-very-large {
  margin-top: 15rem;
}

.push-down-very-small {
  margin-top: 2.5rem;
}

.push-down-super-small {
  margin-top: 1.5rem;
}

// Hides the close button on the Static Google Map
// http://stackoverflow.com/questions/20544932/remove-close-icon-from-the-google-map-info-window
.static-google-map .gm-style-iw + div {
  display: none;
}

.thumbster-gray-super-light-bg {
  background-color: $brand-light-gray;
}

.thumbster-grey-dark-bg {
  background-color: $thumbster-gray-dark;
}

.thumbster-grey-md-dark-bg {
  background-color: $brand-med-dk-gray;
}

.highlight-bg {
  background-color: #fff9e9;
}

.opacity-5 {
  opacity: 0.5;
}

.status-pill {
  margin-right: 1rem;
}

// Text Size Helpers
.font-size-0-5em {
  font-size: 0.5em;
}

.font-size-0-75em {
  font-size: 0.75em;
}

.font-size-1em {
  font-size: 1em;
}

.font-size-1-25em {
  font-size: 1.25em;
}

.font-size-1-5em {
  font-size: 1.5em;
}

.font-size-2em {
  font-size: 2em;
}

.font-size-3em {
  font-size: 3em;
}

.font-size-4rem {
  font-size: 3em;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.line-height-1 {
  line-height: 1;
}

.no-padding {
  padding: 0;
}

.padding-1em {
  padding: 1em;
}

.padding-10px {
  padding: 10px;
}

.padding-left-9px {
  padding-left: 9px;
}

.padding-left-7pct {
  padding-left: 7%;
}

.padding-left-11pct {
  padding-left: 11%;
}

.color-black {
  color: $text-color !important;
}

.small-text {
  font-size: 1.5rem;
}

.pull-up {
  margin-top: -1%;
}

.remove-space-left {
  margin-left: -25%;
}

.no-border-embedded {
  border-style: none;
  box-shadow: 2px 2px 2px 1px;
}

.center-text {
  text-align: center;
}

.left-align-text {
  text-align: left;
}

.custom-modal {
  width: 66%;
}

.max-height-75 {
  max-height: 75%;
}
