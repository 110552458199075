.inventory-widget {
  position: absolute;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  right: 25px;
  min-width: 143px;
  top: 130px;
  z-index: 1;
  &__select-header {
    display: flex;
    flex-direction: row;
    flex-wrap:nowrap;
    padding: 1rem;
    @apply bg-gray-light;
    &__select:first-child {
      margin-right: 2rem;
    }
    &__select {
      font-size: 12px;
      height: 45px;
      min-width: 110px;
    }
  }
  &__controls {
    height: 30px;
    width: 19px;
    border-radius: 2px 0 0 2px;
    @apply bg-white relative;
    &_arrow {
      margin-left: .25rem;
      font-size: 18px;
      top: 6px;
      @apply transform rotate-180 relative;
      &__open {
        margin-left: 0;
      }
    }
    &__open {
      top: 74px;
      @apply transform rotate-180 relative;
      border-radius: 0 2px 2px 0;
    }
  }
  &__content {
    @apply bg-white;
    min-height: 30px;
    margin-top: .75rem;
    box-shadow: 0 1px 4px 0  rgba(0, 0, 0, 0.3);
    border-radius: 2px;
  }
  &__title {
    @apply bg-white w-full;
    box-shadow: 0 1px 4px 0  rgba(0, 0, 0, 0.3);
    border-radius: 0 2px 2px 0;
    &_text {
      position: relative;
      top: 3px;
      left: .5rem;
    }
  }
  &__table {
    width: 100%;
    border-collapse: separate;
    &__wrapper {
      overflow: auto;
      max-height: 200px;
    }
    & td, & th {
      padding: .25rem .5rem;
      text-align: center;
      width: 24px;
    }
    & thead {
      & tr {
        & th {
          padding-bottom: .75rem;
          position: sticky;
          top: 0;
          z-index: 50;
          @apply bg-white;
          border-bottom: 1px solid #131514;
        }
      }
    }
    & th:first-child, & td:first-child {
      text-align: left !important;
    }
    & tr:nth-child(even) {
      background-color: #f9f9f9;
    }
    & tfoot {
      & tr {
        & td {
          border-top: 1px solid #131514;
          position: sticky;
          bottom: 0;
          z-index: 50;
          background-color: white;
        }
      }
    }
  }
  &__empty {
    & td {
      & div {
        color: #6a7789;
        text-align: center !important;
        width: 226px;
        padding: 1rem;
        margin: auto;
      }
    }
  }
}