
@import '../../styles/new/variables';

.noNotifications {
  color: $med-dk-gray;
  margin-top: 1rem;
  text-align: center;
  font-size: 16px;
}

.panelFooter {
  padding: .75rem 1.5rem;
}

.readOnlyContainer {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.readOnlyItem {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.readOnlyText {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-weight: 100;
  font-size: 18px;
}

.readOnlyIcon {
  margin-left: 1rem;
  position: relative;
  top: 5px;
}

.linkIcon {
  margin-right: .75rem;
  position: relative;
  top: 5px;
}
