@import '../../styles/new/variables';
.discountTaxForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 8px; /* matching bootstrap form td padding */
}

.discountAndTaxesContainer {
  width: 40%;
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
}

.displayFormButton {
  color: $tertiary;
  font-size: 14px;
}

.discountAndTaxesButton {
  margin-left: auto;
}

.subtotal {
  padding-top: .75rem;
  font-weight: 500;
}

.totalContainer {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  width: 60%;
  margin-left: auto;
}

.total {
  font-size: 18px;
  padding-top: .75rem;
  font-weight: 500;
}

.showDiscountAndTaxTotal {
  border-top: 1px solid $dark-gray;
}

.ticketMessaging {
  font-style: italic;
  color: $medium-gray;
  margin: .5rem 0 .5rem auto;
}

