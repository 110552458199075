@import '../../styles/new/variables';

.availability {
  background-color: $white;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .7);
  color: $dark-gray;
  font-size: 1.4rem;
  font-weight: 300;
  padding: 2rem 1.5rem;
}

.header {
  font-size: 2.5rem;
  font-weight: 300;
  margin: 0;
}

.tableWrapper {
  background-color: $white;
  border-radius: .125rem;
  margin: 1rem 0 0 0;
  padding: 1rem 1.5rem 4rem 1.5rem;
}

.spinner {
  height: 4.5rem;
  margin: 0 auto;
  width: 4.5rem;
}

.error {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.errorIcon {
  color: $warning;
  font-size: 4rem;
}
