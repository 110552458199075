@import '../../styles/new/variables';

.imageSettingsContainer {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid $med-dk-gray;
}

.uploadDisclaimer {
  font-size: 10px;
  margin-bottom: 2rem;
}

.uploadDisclaimer, .uploadTitle {
  color: $med-dk-gray;
  font-style: italic;
}

.logoImage {
  margin-bottom: 2rem;
}

.removeButton {
  margin-left: 1.5rem;
}