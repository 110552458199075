@import '../../styles/new/variables';

.readyForExport {
  color: $primary;
  text-align: center;
}

.readyForExportNoWP {
  padding-bottom: 3rem;
}

.exportDisclaimer {
  color: $alert;
  font-size: 16px;
  text-align: center;
  margin-bottom: 2rem;
}

.exportBtn, .exportDisclaimer, .qboConfirmationBtn {
  font-weight: 500;
}