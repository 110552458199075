@import '../../styles/new/variables';
@import '../../styles/new/mixins';

.formHeader {
  padding: 0 !important; // overwriting bootstrap h4 styling
}
.connection {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  justify-content: space-between;
  margin: 1rem 0 5rem 0;
}

.wpLink {
  margin-right: 2rem;
}

.formContainer {
  padding: 2rem;
}

.settingsContainer, .wpFormSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.formSection, .formSection {
  display: flex;
  flex-direction: column;
}

.formSection, .radioLabel, .receiptSection {
  width: 100%;
}

.radioBtn {
  margin-left: .5rem;
  font-size: 16px;
}

.sectionTitle, .sendInvoicesTitle, .checkBoxLabel, .sectionTitle500 {
  font-size: 16px;
}

.sublabel {
  color: $medium-gray;
  margin-bottom: 1.5rem;
  line-height: initial;
}

.invoicePreview {
  font-size: 18px;
  margin-bottom: 1.5rem;
}

.submitBtnTop, .learnMoreBtn {
  height: fit-content;
  align-self: center;
}

.sectionTitleSecondary {
  margin-left: .5rem;
}

.radioBtnDescription, .sectionTitleSecondary, .wpConnectLink {
  color: $med-dk-gray;
}

.sectionTitleContainer {
  margin-bottom: 2rem;
}

.receiptExampleContainer {
  padding: 1rem;
}

.receiptExampleContainerFull {
  height: 100%;
}

.settingsDisplayMd, .sendInvoicesTitle, .invoicePreview, .sectionTitle500 {
  font-weight: 500;
}

.preview-iframe {
  width: 100%;
  height: 100%;
}

.templateSection, .receiptSection {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.receiptSection {
  margin-top: 20rem;
}

.emailReceiptTitle {
  margin: 1rem 0;
}

.optionsSection {
  margin: 2.75rem 0;
}

@include respond-to('lg') {
  .receiptSection, .templateSection {
    display: flex;
    flex-direction: column;
  }

  .templateSection {
    width: 55%;
  }

  .formSection {
    width: 41%;
    margin-left: 3%;
  }
}

.paymentTermsSelect {
  width: 33% !important;
}

.saveButtonContainer {
  padding-top: 1.75rem;
}

.previewButtonContainer {
  padding-bottom: .75rem;
}

.saveButtonContainer, .previewButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.namePhoneSection {
  display: flex;
  flex-direction: row;
}

.namePhoneSection, .defaultTerms {
  width: 100%;
}

.companyName {
  margin-right: 2rem;
  width: 65%;
}

.companyPhone {
  width: 35%;
}

.receiptSection {
  margin-top: 2rem;
}

.subjectLine {
  display: flex;
  input {
    flex-grow: 1
  }
}

.subjectLineSuffix {
  margin-left: 2rem;
  align-self: flex-end;
  white-space: nowrap;
}

.buttonRightSpacing {
  margin-right: 2rem;
}