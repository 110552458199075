@import '../../styles/new/variables';
@import '../../styles/new/mixins';

.invoice {
  padding: 3rem 2rem 1rem 2rem;
}

.closeInQboBtn {
  align-self: flex-end;
  margin: 2rem 0 1rem 0;
}

.closeInQboBtnLink {
  color: $dark-gray;
  &:active {
    color: $dark-gray
  }
}

.titleContainer {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.title {
  font-weight: 500;
  margin: 0;
}

.printText {
  margin: 0 0 0 .5rem;
}

.printBtn {
  align-items: center;
  color: $link-blue;
  display: flex;
  justify-content: center;
}

.printLoader {
  color: $link-blue;
}

.bill {
  width: 100%;
}

.billTitle {
  font-size: 1.6rem;
  font-weight: 500;
  margin: 1rem 0 0 0;
  padding: 0;
  text-align: left;
}

.billAddress {
  line-height: 1.2;
  margin: .75rem 0 1rem 0;
}

@include respond-to('lg') {
  .invoice {
    margin-right: 2rem;
  }
}
