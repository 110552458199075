@import '../../../styles/new/variables';

.checkbox {
  input[type='checkbox'] {
    appearance: none;
    display: none;
    opacity: 0;
    z-index: -1;
  }

  cursor: pointer;
  display: inline-block;
  margin: 0;
  overflow: hidden;
  position: relative;
}

.disabled {
  cursor: not-allowed;
}

.unchecked {
  background-color: $white;
  border: 2px solid $med-dk-gray;
  border-radius: .325rem;
}

.checked {
  background-color: $primary;
  border: 2px solid $primary;
  border-radius: .325rem;
}

.small {
  height: 17px;
  margin-bottom: -.25rem;
  width: 18px;
}

.large {
  width: 24px;
  height: 23px;
}

.checkIconWrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  width: 100%;
}

.checkIcon {
  color: $white;
  position: absolute;
}

.checkIconSmall {
  font-size: 1.8rem;
}
