* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  background-color: $mint-gray;
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 100;
}

// Mozilla fix for responsive tables
// Read http://getbootstrap.com/css/#tables-responsive#callout-tables-responsive-ff-fieldset
@-moz-document url-prefix() {
  fieldset {
    display: table-cell;
  }
}

#map {
  width: 100%;
  height: calc(100vh - 52px - 65px - 65px);
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.small-margin {
  margin-top: -10px;
}

#dispatch-tickets-list {
  @media(min-width: 769px) {
    height: calc(100vh - 52px - 65px - 16px);
  }

  overflow-y: auto;
  overflow-x: hidden;
}

.dispatcher-badge {
  @apply inline-block min-w-min text-center align-middle whitespace-nowrap;
  padding: 4px 6px;
  font-size: 12px;
  border-radius: 10px;
  line-height: 1;
}

.button {
  box-shadow: 0 1px 4px 0px rgba(0,0,0,0.3);
  @apply rounded uppercase px-2;
  &_primary {
    @apply bg-green hover:bg-green-10-dark;
  }
  &_warning {
    @apply bg-red hover:bg-red-10-dark text-white;
  }
  &_white {
    @apply bg-white hover:bg-white-10-dark;
  }
}

.driver-list-badge {
  width: 7%;
}

.link {
  font-weight: 500;
  color: $brand-tertiary;
}

#dispatch-location-tickets-list {
  @media(min-width: 769px) {
    height: calc(100vh - 52px - 52px - 66px - 70px - 65px - 52px);
  }
  overflow-y: auto;
  overflow-x: hidden;
}

.login {
  &__h3 {
    font-size: 24px;
  }
  &__app-icons {
    height: 50px;
    width: auto;
  }
}

.navbar {
  margin-bottom: 0px;
}

.dropdown-menu > li > button {
  background-color: $dropdown-bg;
  border: none;
  clear: both;
  color: $dropdown-link-color;
  display: block;
  font-weight: 500;
  line-height: $line-height-base;
  padding: 3px 20px;
  text-align: left;
  width: 100%;
}

.dropdown-menu > li > button:hover {
  color: $dropdown-link-hover-color;
  background-color: $dropdown-link-hover-bg;
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
  .navbar-nav .open .dropdown-menu {
    background-color: $thumbster-gray-light-2x !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.filter-link {
  margin-top: 2.5%;
  color: $brand-primary;
  cursor: pointer;
}

.user-ticket-action-icons {
  margin-left: -20px;
}

//::-webkit-scrollbar {
//  display: none;
//}

.space-left {
  margin-left: 10px;
}

.space-right {
  margin-right: 10px;
}

.large-icon {
  font-size: 4rem !important;
  margin-top: 0.5%;
  margin-right: 2%;
}

.very-large-icon {
  font-size: 8rem !important;
  margin-top: 1%;
}

.medium-icon {
  font-size: 3rem !important;
  margin-top: 0.5%;
  margin-right: 2%;
}

.thumbster-button-icon {
  margin-left: 10px;
}

.no-margin-bs-xs {
  @media(max-width: 768px) {
    margin-left: 0;
  }
}

.back-button-icon {
  font-size: 4rem !important;
  margin-top: -1%;
}

.white-text {
  color: $white;
}

.med-dark-gray-text {
  color: $brand-med-dk-gray !important;
}

.ticket-buttons {
  margin-left: 3%;
  margin-top: 1.5%;
}

.assigned-ticket-button {
  margin-left: 5%;
  margin-bottom: 2%;
}

.margin-top-0-5-pct {
  margin-top: 0.5%;
}

.margin-right-0-5-pct {
  margin-right: 0.5%;
}

.dispatch-top-panel {
  height: 52px;
}

.dispatch-panel {
  margin-bottom: 0;
}

/* overwriting default scoped bootstrap style */
.ticket-details-container {
  .panel-info {
    .panel-heading {
      .badge {
        color: $brand-dark-gray ;
      }
    }
  }
}

.nav-icon {
  line-height: 5px !important;
}

/* Info Bar Styling */
#dispatch-info-bar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.panel-no-margin-bottom {
  margin-bottom: 0;
}

.back-to-tickets-button {
  height: 52px;
}

.user-item-display {
  background-color: $brand-light-gray !important;
  height: 52px !important;
}

.unassigned-item-display {
  background-color: $brand-secondary !important;
  height: 52px !important;
}

.badge-white {
  margin-top: 2%;
  background-color: $white;
  color: $gray-base;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.unread-notification {
  background-color: rgba($brand-primary-lt, 0.5);

  & h5 {
    color: $brand_med_dk_gray;

    & .btn-link {
      color: $brand_med_dk_gray;
    }
  }
}

.read-notification {
  & h5 {
    color: $brand_med_dk_gray;

    & .btn-link {
      color: $brand_med_dk_gray;
    }
  }
}

.error-notification {
  background-color: rgba($brand-danger, 0.5);
}

.med-dk-gray {
  color: $brand_med_dk_gray;
}

.center-logo-text {
  display: flex;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dark-background {
  background-color: $thumbster-gray-dark;
}

a {
  font-weight: 500 !important;
}

img[src*="gstatic.com/"], img[src*="googleapis.com/"] {
  max-width: none !important;
  max-height: none !important;
}

.resource-info-window {
  max-height: 200px;
  overflow-y: scroll;
}

.site-info-window {
  max-height: 200px;
  overflow-y: scroll;
}

@media(min-width: 1100px) {
  .btn-group-toggle {
    margin-right: 5%;
  }
}

.wordwrap {
  word-wrap: break-word;
}

.med-dk-gray-color {
  color: $brand-med-dk-gray;
}

.new-request-tooltip {
  padding: 6px;
  & span {
    text-align: left !important;
    line-height: 1 !important;

  }
}

.cell-no-padding-x {
  padding: 8px 0 !important; // overwriting bootstrap table defaults
 }

.dis-select {
  cursor: pointer;
  border: 0;
  border-radius: 0;
  color: $dark-gray;
  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
}

.self-center {
  align-self: center;
}