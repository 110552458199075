@import '../../../styles/new/mixins';
@import '../../../styles/new/variables';

.panel, .searchContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form {
  width: 100%;
}

.inputs {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
}

.btns {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0 0 0;
  width: 100%;
}

.clearBtn {
  margin: 0 0 0 1rem;
}

.wpad {
  align-items: center;
  background-color: $primary-lt;
  border-radius: .25em;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1.5rem 1rem;
  max-width: 25vw;
  max-width: 250px;
  border: none;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  margin: 0 0 2.25rem 2rem;
}

.ad {
  font-size: 18px;
  font-weight: 400;
  line-height: .1;
  margin: 1em 0 .8em;
}

.wpad h6 {
  background-color: $primary;
  border-radius: 50px;
  margin: 0;
  padding: .2rem .6rem;
  font-weight: 500;
  font-size: 12px;
}

.wpad p {
  text-align: center;
  line-height: 1.2em;
}

.wpad a {
  color: $link-blue;
}

.label {
  padding: 0 15px;
  white-space: nowrap;
}

@include respond-to('md') {
  .label {
    flex-basis: 25%;
  }

  .btns {
    margin: 2rem 0 0 0;
  }
}
