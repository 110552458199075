@import '../../styles/new/variables';
.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .button-icon {
    color: $tertiary;
  }
  i {
    margin-right: .1rem;
  }
}

.modal-input {
  margin-top: 2rem;
}
.modal-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: right;
  button:nth-child(2) {
    span {
      margin-left: .5rem;
    }
  }
}

.spinner-right {
  margin-right: .5rem;
}
.spinner-left {
  margin-left: .5rem;
}