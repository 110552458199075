.background {
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 5000;
}

.modal {
  align-items: center;
  display: flex;
  justify-content: center;
}
