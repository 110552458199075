.statusContainer {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.statusLabelContainer {
  display: inline-block;
  font-size: 1.5em;
  vertical-align: middle;
}

.statusLabel {
  margin-left: 1rem;
  position: relative;
  bottom: .25rem;
}

.qboPanelBody {
  display: flex;
  font-size: 1.4rem;
  justify-content: space-between;
}

.qboTitle {
  font-size: 1.9rem;
  margin: 0;
}

.qboProduct {
  align-items: center;
  display: flex;
  vertical-align: center;
}

.qboProductName {
  margin: 0 0 0 .5rem;
}
