.subtitle { 
  font-size: 1.5rem;
  margin: 1rem 0;
}

.radioBtnBlock {
  display: block;
  margin: .25rem 1rem;
}

.radioBtn {
  margin: .25rem 1rem;
}

.btnContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.defaultSetting {
  font-style: italic;
  margin-left: .25rem;
}