@import '../../styles/new/variables';

.manageCardsModal {
  background-color: $white;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .7);
  color: $dark-gray;
  font-size: 1.4rem;
  font-weight: 300;
  padding: 2rem;
  width: 100%;
  max-height: 50vh;
  overflow: hidden;
  overflow-y: scroll;
}

.manageCardListTitle {
  margin-bottom: 2rem;
}

.manageCardListItem {
  font-size: 16px;
  border-top: 1px solid $dark-gray;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lastManageCardListItem {
  border-bottom: 1px solid $dark-gray;
}

.manageCardListContainer {
  width: 100%;
}


.cardDetailDivider {
  margin: 0 1rem;
}

.manageCardListActions {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  justify-content: space-between;
  margin-left: 2rem;
}

.defaultCardBadge {
  border-radius: 50px;
  background-color: $primary;
  color: $white;
  padding: 0 .75rem;
  max-width: 109px;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.removeCardBtn {
  color: $warning !important;
}

.closeModalBtn {
  display: block !important;
  margin: 2.5rem 0 0 auto;
  font-size: 16px;
}

.closeModalIcon {
  font-size: 18px;
  position: relative;
  top: 4px;
}

.removeCardContainer {
  border-top: 1px solid $dark-gray;
  margin: 2rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
}

.removeCardDetailsContainer {
  margin-left: 1rem;
}

.removeCardButtonContainer {
  font-weight: 500;
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.cancelBtn {
  font-size: 16px;
  margin-right: 1rem;
}

.deleteBtn {
  font-size: 16px;
}

.manageCardsActionWrapper {
  display: flex;
  width: 50%;
  justify-content: end;
  margin: 0 0 0 auto;
}

.addCardSection {
  > h3 {
    margin-top: 0;
    font-weight: 200;
    font-size: 2.4rem;
  }
  > h4 {
    margin: 0;
    padding: 0;
    font-size: 1.8rem;
  }
  .formRow {
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    .fullWidthInput {
      width: 100%;
      > label {
        width: 100%;
      }
    }
  }
}

.nicknameWrapper {
  margin-left: 2rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  label {
    width: 100%;
    color: $dark-gray;
    font-weight: 300;
  }
  .nicknameButtonWrapper {
    display: flex;
    justify-content: space-between;
  }
}

