@import '../../styles/new/mixins';

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.newBtnText {
  display: none;
}

// Selecting the <i/> tag is not ideal but the specificity of the dis-btn-icon is overwriting this class.
i.newBtnIcon {
  margin: 0;
}

@include respond-to('sm') {
  .newBtnText {
    display: inline;
  }

  i.newBtnIcon {
    margin: 0 0 0 1rem;
  }
}
