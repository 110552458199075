@import '../../styles/new/variables';
@import '../../styles/new/mixins';

.btns {
  display: flex;
  flex-direction: column;
  margin: 3rem 0 0 0;
}

.sendBtn {
  margin: 1rem 0 0 0;
  width: 100%;
}

.saveBtn {
  width: 100%;
}

.sendIcon {
  margin: 0 0 0 1rem;
  width: 100%;
}

.dateControl:after {
  content: none;
}

.row {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 0 0;

  // Only nested to overwrite bootstrap form-group class
  > .includeTerms {
    margin: 0;
  }
}

.rowField {
  margin: 0 .5rem;
}

.fullWidth {
  width: 100%;
}

.editBtn {
  color: $link-blue;
  font-size: 1.3rem;
  padding: 0;
}

.modal {
  background-color: $white;
  padding: 2rem;
}

.modalSaveBtn {
  margin: 0 0 0 1rem;
}

.modalBtns {
  display: flex;
  justify-content: flex-end
}

.conditionsTextarea {
  min-height: 200px;
  min-width: 500px;
}

@include respond-to('sm') {
  .btns {
    flex-direction: row;
    justify-content: space-between;
  }

  .saveBtn {
    width: auto;
  }

  .sendBtn {
    margin: 0 0 0 1rem;
    width: auto;
  }
}


@include respond-to('lg') {
  .btns {
    flex-direction: column;
  }

  .sendBtn {
    margin: 1rem 0 0 0;
  }
}


@include respond-to('2xl') {
  .btns {
    flex-direction: row;
  }

  .sendBtn {
    margin: 0 0 0 1rem;
  }
}
