@import '../../../styles/new/variables';
@import '../../../styles/new/mixins';

.buttonContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  margin-top: 1.25rem;
  :not(:last-child) {
    margin-right: 1rem;
  }
}

@include respond-to('lg') {
  .buttonContainer {
    margin-right: -15px;
  }
}
