@import '../../styles/new/variables';
@import '../../styles/new/mixins';

.detailsItem {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.detailsIcon {
  font-size: 20px;
  margin-right: .75rem;
}

.detailsInfoRight {
  margin-left: auto;
  font-size: 16px;
}

.detailsInfo {
  font-size: 16px;
}

.accountNotes {
  margin-left: 2.75rem;
}

.detailType {
  font-size: 16px;
  font-weight: 500;
}

.qboListItem {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.qboBtn {
  font-size: 1.6rem;
}

.errorText {
  display: block;
  font-size: 1.2rem;
  color: $warning;
}

.cardSpinner {
  margin: 1rem auto;
}

.cardListItem {
  margin-bottom: 0;
}

.qboSpinner {
  min-height: 2rem;
  margin: 1rem 0;

  div {
    color: $medium-gray;
  }
}

.manageCardsWrapper {
  text-align: right;  
  flex-grow: 2;
}

@include respond-to('lg') {
  .manageCardsModalContainer {
    width: 35%;
  }
}
