@import '../../../styles/new/variables';

.inputContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-basis: 100%;
  margin-bottom: 2rem;
}

.textInput {
  flex-basis: 50%;
  margin-right: 4rem;
}

.searchBtn {
  margin-top: 2rem;
  height: 75%;
}

.errorMessage {
  color: $warning;
}

.infoWindow {
  margin-top: .5rem;
}
