@import '../../styles/new/variables';
@import '../../styles/new/mixins';

.soloForm { 
  width: 100%;
}

.manageCardsModalContainer{
  width: 90%;
  position: relative;
  top: -15%;
}

.newCardsModalContainer {
  width: 40%;
  position: relative;
}

.cardDetails {
  margin-top: 2rem;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  p:first-child {
    flex-grow: 0;
  }
}

.ccImgContainer {
  flex-grow: 0;
  position: relative;
  top: 2px;
  width: 116px;
  height: 29px;
  margin: 0 0 1rem 1rem;
}

.ccImg {
  width: 100%;
  height: auto;
}

.radioInputContainer {
  margin: .5rem 0;
}

.radioInput {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.radioLabel {
  margin-left: .5rem;
  width: 100%;
}

.halfWidthInput {
  width: 45%;
}

.cardQueryError {
  color: $warning;
  text-align: right;
}

.fullWidthInput {
  width: 100%;
}

.newCardBtn {
  margin-bottom: 2rem;
  font-size: 1.6rem;
}

.multiInputSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.billingAddressDisplayNone {
  margin: 0;
}

.billingAddress {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.billingDetailsIcon {
  font-size: 22px;
  margin-right: 1rem;
}

.billingAddressTitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 2rem;
  margin-bottom: 1.5rem;
}

.billingDetailsSavedCardsView {
  margin-left: -3rem;
}

.hidden {
  display: none;
}

.deliveryInput {
  margin-left: auto;
}

.quarterWidthInput {
  width: 20%;
}

.saveCardLabel {
  font-size: 16px;
  margin-top: 2rem;
}

.paymentError {
  border: 1px solid $warning;
  background-color: $warning-light;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem;
}

.errorIcon {
  color: $warning;
  margin-right: 1rem;
}

.paymentErrorHeader {
  font-size: 1.6rem;
  color: $warning;
  margin-bottom: 1rem;
}

.errorDetails {
  margin: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  color: $dark-gray;
}

.btnContainer {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btn {
  min-width:1rem;
  justify-content: center;
  &:nth-child(2) {
    justify-content: center;
    margin-left: 1.75rem;
    min-width:20.3rem;
  }
  & div {
    margin: 0 auto;
  }
}

.manageCardsWrapper {
  text-align: right;  
  flex-grow: 2;
}

.manageCardsBtn {
  font-size: 10px;
  margin: .25rem 0 0 auto;
  display: block !important;
}

.wpImgContainer {
  height: 37px;
  width: 134px;
  margin: 2rem 0 0 auto;
}

.wpImg {
  width: 100%;
  height: auto;
}

@include respond-to('lg') {
  .manageCardsModalContainer {
    width: 35%;
  }
}
