.soloForm { 
  width: 100%;
}

.paymentOptions {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0;
  text-align: left;
}

.paymentOptions input[type=text] {
  margin-left: 1rem;
}

.blockInput {
  display: inline-block;
}

.paymentInfo {
  display: flex;
  flex-direction: column;
}

.checkNumberInput {
  margin-left: 1rem;
}

.bottomSpacing {
  padding-bottom: 1.5rem;
}

.paymentInfo label {
  color: #586777;
  display: block;
  padding-top: 1rem;
}

.paymentInfo input,
.paymentInfo textarea,
.fullWidthInput,
.react-datepicker-wrapper {
  width: 100%;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
}

.btn {
  min-width:1rem;
  justify-content: center;
  &:nth-child(2) {
    justify-content: center;
    margin-left: 1.75rem;
    min-width:20.3rem;
  }
  & div {
    margin: 0 auto;
  }
}
