@import '../../../styles/new/variables';

// Specificity is high in this file in order to overwrite other styles. Somebody thought it was a good idea to have styles applied
// with a very high specificity src/styles/react-datepicker-overrides.scss.
:global(.react-datepicker__input-container) input[type='text'].calendar {
  border: none;
  border-bottom: 1px solid $dark-gray;
  font-size: 1.4rem;
  height: 3rem;
  padding: .4rem;
  margin-right: .8rem;
  margin-left: .8rem;
  width: 12.8rem;
  &:focus {
    border-bottom: 2px solid $primary;
    box-shadow: none;
    outline: none;
  }
}

.popper {
  line-height: normal;
  z-index: 40;
}
