@import '../../styles/new/variables';

.sortContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 14px;
  padding: 8px 16px;
  background-color: $mint-gray;
}

.selectContainer {
  flex-grow: 1;
  display: flex;
  flex-wrap: nowrap;
  flex-basis: 50%;
}

.sortSelect {
 background-color: $mint-gray;
 font-size: 14px;
 margin: 0 .5rem;
 padding-right: 2rem;
 position: relative;
 top: -2px;
}

.sortButton {
  flex-wrap: nowrap;
  display: flex;
  flex-basis: 25%;
}

.sortIcons {
  font-size: 16px;
  width: 15%;
  margin: auto 0 auto .5rem;
  width: auto;
  position: relative;
  top: -2px;
  width: 1.25rem;
}


@media(max-width: 1600px) {
  .sortContainer {
    flex-wrap: wrap;
  }
  .selectContainer {
    flex-basis: 100%;
    margin-bottom: 1rem
  }
}

@media(max-width: 992px) {
  .sortContainer {
    flex-wrap: nowrap;
  }
  .selectContainer {
    flex-basis: 50%;
    margin-bottom: 0;
  }
}
