@import '../../../styles/new/variables';

.newCardsModal {
  background-color: $white;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .7);
  color: $dark-gray;
  font-size: 1.4rem;
  padding: 2rem;
  width: 100%;
  min-height: 50vh;
}

.billingAddressHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    margin: 0;
    padding: 0;
  }
  margin-bottom: 1rem;
  span {
    position: relative;
    margin-left: 1rem;
    i {
      position: absolute;
      top: -2px;
    }
  }
}


.billingAddressTitle {
  font-size: 1.6rem;
}

.closeModalBtn {
  position: relative;
  display: block !important;
  font-size: 16px;
  top: -10px;
}

.closeModalIcon {
  font-size: 18px;
  position: relative;
  top: 4px;
}

.cancelBtn {
  font-size: 16px;
  margin-right: 1rem;
}

.fullWidthInput,
.inlineFieldsContainer {
  margin-bottom: 1rem;
}

.fullWidthInput > label,
.fullWidthInput >label > input,
.fullWidthInput textarea{
  width: 100%;
}

.inlineFieldsContainer {
  display: flex;
  justify-content: space-between;
  .inlineField {
    width: 45%;
    > label {
      width: 100%;
      > input {
        width: 100%;   
      }
      > textarea {
        width: 100%;
      }
    }
  }
  &.spaceAround {
    margin-top: 2rem;
    justify-content: space-around;
  }
}
