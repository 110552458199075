@import '../../styles/new/variables';

.accountVerifyError {
  padding-left: 1.5rem;
  margin-top: 2rem;
}

.exportSuccessContainer {
  text-align: center;
}

.successText {
  margin-bottom: 2rem;
}

.successText, .successIcon {
  color: $primary;
}

.alertText {
  color: $alert;
  font-weight: 500;
  margin-bottom: 4rem;
}

.btnContainer {
  margin-bottom: 2rem;
}

.exportStatusContainer {
  font-size: 16px;
  display: inline-block;
  text-align: left;
}

.transactionError, .errorIcon {
  color: $warning;
}

.retryBtn, .qboLink {
  color: $tertiary;
  font-weight: normal !important; /* overwriting bootstrap link style */
  margin-left: .5rem;
}

.exportStatusIcons {
  font-size: 20px;
  position: relative;
  top: .25rem;
  margin-right: .75rem;
}

.paymentInvoiceExportContainer {
  margin-bottom: 4rem;
}
