.react-datepicker {
  font-size: .95em !important;
}
.react-datepicker__input-container {
  display: block !important;

  input {
    width: 100%;
    display: block;
    @extend .form-control;
  }
}

.react-datepicker__day-name, .react-datepicker__day {
  width: 2.5rem !important;
}

.react-datepicker__current-month {
  font-size: 1.3rem !important;
}


