@import '../../styles/new/mixins';

.pageTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bulkCreateContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.bulkCreateFormContainer {
 width: 100%;
}

.bulkCreateHelpContainer {
  width: 100%;
}

@include respond-to('lg') {
  .bulkCreateFormContainer {
    width: 58.333%;
    margin-right: 8.333%;
  }
  
  .bulkCreateHelpContainer {
    width: 33.33333%;
  }  
}