@import '../../styles/new/mixins';

.statusContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 1.5em;
  justify-content: space-between;
}

.currentStatus {
  margin-right: 1rem;
  display: inline-block;
}

.textInput {
  width: 100%;
  &:first-of-type {
    margin-right: 0;
  }
}

.nameShortCode {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.editMessaging {
  margin-bottom: 4rem;
}

@include respond-to('lg') {
  .textInput {
    width: 50%;
    &:first-of-type {
      margin-right: 3rem;
    }
  }

  .nameShortCode {
    flex-wrap: nowrap;
  }
}
