@import '../../../styles/new/variables';

.feeTypeTable {
  width: 100%;
}

.subTable > tbody > tr > td, .subTable > thead > tr > th {
  padding: 0 8px;
}

.invoiceLineItemTable {
  margin-bottom: 0 !important; // overwriting bootstrap
}

.lastTicketFee {
  padding-bottom: .5rem;
}

.totalCell {
  background-color: $white;
  font-size: 16px;
  border-top: none !important;
}

.totalTable {
  margin-top: 1rem;
}

.totalFontMedium {
  font-weight: 500;
  padding-top: 8px;
}

.totalCellBorderTop {
  border-top: 1px solid $dark-gray !important;
}

.qboFeeMessaging {
  font-size: 12px;
  color: $medium-gray;
  position: relative;
  top: -3rem;
}

.noTicketsMessage {
  background-color: $white !important; // Overwriting bootstrap table striped
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}

.noTicketsMessageCell {
  padding-top: 2rem !important; // Overwriting bootstrap td styling
}
