/*
New variables file so that we can start to deprecate the use of bootstrap and not import its variables into any of our
new code.
*/

$primary: #33CA80 !default;
$primary-dk: #0A3936 !default;
$primary-lt: #D7F7D8 !default;
$secondary: #FFE66C !default;
$tertiary: #0B51AD !default;
$alert:	#FF8F00 !default;
$warning: #F93B59 !default;
$warning-light: rgba(249, 59, 89, .1) !default;
$dark-gray: #131514 !default;
$med-dk-gray: #434A54 !default;
$medium-gray: #6A7789 !default;
$light-gray: #E7E9ED !default;
$mint-gray:	#F4F9F6 !default;
$shadow: #DDD !default;
$white:	#FFFFFF !default;
$link-blue: #0077CD;

$font-weight-normal: 300;
$font-weight-bold: 700;

/*
sizes of things
*/
$input-height: 37px;

$breakpoints: (
  'sm': 640px,
  'md': 768px,
  'lg': 1024px,
  'xl': 1280px,
  '2xl': 1536px
);
