@import '../../../../../../styles/new/variables';

.listSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &.listTop {
    align-items: start;
  }
}

.listHeader {
  margin-bottom: .5rem !important;
}

.listText {
  color: $medium-gray;
  font-size: 1.3rem !important;
}

.listRightWrapper {
  margin-top: .2rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  > i {
    color: $medium-gray;
    font-size: 4rem;
  }
}