.header {
  display: flex;
  justify-content: space-between;
}

.secondaryHeader {
  font-size: 24px;
  margin: 46px 0 23px 0;
}

.editBtn {
  margin-right: .25rem;
}

.haulerProfileContainer {
  margin-bottom: 5rem; // can likely be removed when NormalLayouContainer is refactored to remove bootstrap
}
