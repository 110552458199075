@import '../../styles/new/variables';

.table {
  line-height: 1.6rem;
  text-align: center;
  width: 100%;
}

.header {
  border-top: 1px solid $medium-gray;
  border-bottom: 1px solid $medium-gray;
  background-color: $mint-gray;

  th {
    font-weight: 500;
    padding: .75rem;
    text-align: center;
  }

  th:first-child {
    text-align: right;
  }
}

.dataRow {
  > td {
    line-height: 0;
    padding: .5rem;
  }

  td:first-child {
    padding: 0 .5rem;
    text-align: left;
  }
}

.borderRight {
  border-right: 1px solid $medium-gray;
}

.middleColumn {
  vertical-align: middle;
}

.firstCol {
  font-size: 1.6rem;
  font-weight: 700;
  vertical-align: middle;

  &Text {
    margin: auto;
  }
}

.daysOut {
  input {
    max-width: 60px;
  }
}

.mustCallCheckbox {
  background-color: red;
  border: none;
}

.checkedMustCallIcon {
  color: $white;
  font-size: 20px;
}

.uncheckedMustCallCheckbox {
  background-color: $white;
  border: 2px solid $med-dk-gray;
  border-radius: .325rem;
}

.uncheckedMustCallIcon {
  color: $dark-gray;
  font-size: 20px;
}

