.spinner {
  height: 1.5rem;
  width: 1.5rem;
}

.centered {
  margin: 0 auto;
}

.hidden {
  display: none;
}
