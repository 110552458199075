@import '../../styles/new/variables';
@import '../../styles/new/mixins';

.warningText {
  color: $warning;
  font-size: 16px;
  padding: 3rem;
}

.invoiceDetailsBtn {
  width: 100%;
  display: block !important;
}

.invoiceDetailsBtnMarginTop {
  margin-top: 1rem;
}

.oldReferenceText {
  font-style: italic;
  width: 100%;
}

.selectWrapper {
  position: relative;
}

.billingStatusLabel {
  background-color: $primary-dk;
  color: $white;
  padding: 2px 6px;
  border-radius: 50px;
}

.qboLink {
  color: $dark-gray;
  margin-left: 1rem;
  &:hover {
   color: $dark-gray !important;
   text-decoration: none !important;
  }
}

.selectArrow {
  color: $white;
  font-size: 18px;
  position: absolute;
  display: inline-block;
  right: 5px;
  top: 3px;
  pointer-events: none;
}

.billingStatusSelect {
  color: white;
  min-width: 145px;
  background-color: $medium-gray;
  font-size: 12px;
  padding: 4px 4px 4px 12px;
  border-radius: 50px;
  box-shadow: none;
  line-height: normal;
  outline: none !important;
  background-image: none !important;
  &:focus {
    box-shadow: none !important;
  }
}

.displayFormButton, .editDescriptionButton, .clearDescription {
  color: $tertiary;
  font-size: 14px;
}

.clearDescription {
  margin: 1rem auto auto 2rem;
}

.editDescriptionButton {
  margin-left: .75rem;
}

.discountAndTaxesButton {
  margin-left: auto;
}

.discountAndTaxesContainer {
  display: flex;
  flex-direction: column;
  margin: 2rem 0 2rem auto;
  width: 60%;
}

.totalDisplay, .invoiceDetailTotal, .invoiceDetail {
  font-weight: 500;
}

.feeHeading, .descriptionContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.textCenter {
  text-align: center;
}

.containerBottom {
  margin-top: 2rem;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.formButton {
  margin-right: 2rem;
}

.formModalButtonContainer > button:nth-child(2) {
  margin-left: auto;
  margin-right: 2rem
}

.totalContainer {
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  margin-left: 2.5rem;
}

.showDiscountAndTaxTotal {
  border-top: 1px solid $dark-gray;
}

.total {
  font-size: 18px;
  padding-top: .75rem;
}

.subtotal {
  padding-top: .75rem;
  font-size: 16px;
}

.feeTotal {
  font-size: 18px;
  margin-top: 2rem;
  flex-basis: 5%;
}

.ticketFeeItemContainer {
  display: flex;
  flex-direction: column;
}

.ticketFeeItem {
  padding: .75rem;
}

.ticketFeeFormSection {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
}

.ticketFeeDescription, .editingDescription {
  margin-top: -2rem;
  margin-left: 3.75rem;
}

.ticketFeeDescription {
  display: flex;
}

.ticketFeDescriptionContainer {
 display: flex;
 flex-direction: row;
 flex-wrap: nowrap;
 flex-basis: 50%;
}

.removeFeeButton, .invoiceDetailsStatus {
  margin-right: 1.75rem;
}

.removeFeeIcon {
  color: $warning;
  font-size: 18px;
  position: relative;
  top: .5rem;
}

.feeTypeName {
  font-size: 18px;
  margin: auto 0;
}

.ticketFeeQtyPrice {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-basis: 45%;
  justify-content: space-between;
}

.feeTypeBtnDescription {
  display: flex;
  flex-direction: row;
  flex-basis: 40%;
}

.ticketFeeQtyPrice > label {
  margin-right: 3rem;
}

// ticket form unsaved modal

.formModalContainer {
  width: 100%;
}

.formModalBody {
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formModalIcon {
  color: $alert;
  font-size: 48px;
}

.formModalText {
  font-size: 24px;
}

.formModalDetails {
  font-size: 16px;
  margin-bottom: 3rem;
}

.formModalButtonContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

@include respond-to('lg') {
  .containerBottom {
    flex-wrap: nowrap;
  }

  .formModalBody {
    width: 40%;
  }

  .ticketFeeQtyPrice {
    flex-basis: 40%;
  }
}


.wastepayAd {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: $mint-gray;
  margin-top: 3rem;
  padding: .75rem;
  border: none;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}

.wastepayAd > * {
  align-self: center;
}

.wastepayAdTitle {
  font-weight: 500;
  font-size: 16px;
  margin: 0 1rem;
}

.invoiceView, .editingInvoiceDetails {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.editingInvoiceDetails {
  margin-bottom: 1.5rem;
}

.invoiceTotals {
  width: 66%;
  margin-right: 2.5rem;
  flex-grow: 1;
}

.invoiceDetailsContainer {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  margin-bottom: auto;
}

.invoiceDetail {
  margin-right: .5rem;
}

.invoiceLink, .invoiceDetailTotal {
  font-size: 16px;
  align-self: center;
  line-height: 1.25;
}

.invoiceDetails {
  margin: .5rem 0 2rem 0;
}

.invoiceDetailButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  :nth-child(2) {
    margin-left: .5rem;
  }
  &.buttonContainerSolo {
    justify-content: center;
  }
}

.newBadge {
  border-radius: 50px;
  background-color: $primary;
  padding: 0 .75rem;
  font-weight: 500;
}

.wastepayLink {
  margin-left: .5rem;
}

/* TicketInvoiceTable */

.invoiceTotalContainer {
  margin-left: auto;
  font-size: 16px;
  display: table;
  width: 30%;
}

.invoiceTotalContainerRow {
  display: table-row;
}

.invoiceTotalContainerCell {
  display: table-cell;
  padding: 4px 8px;
  text-align: right;
}

.invoiceTotal {
  font-weight: 500;
  font-size: 18px;
  border-top: 1px solid $dark-gray;
  border-collapse: collapse;
  padding: 8px 0;
  display: table-cell;
  padding: 4px 8px;
  text-align: right;
}

.createInvoiceBtn {
  min-width: 19rem;
  min-height: 3rem;
  & div {
    margin: 0 auto;
  }
}

.invoiceMessaging {
  font-style: italic;
  color: $medium-gray;
  margin: .5rem 0 .5rem auto;
}

.feesInput {
  & input {
    &:invalid {
      color: $warning;
      box-shadow: inset 0 -2px 0 rgba($warning, .2);
    }
    &:focus:invalid {
      box-shadow: inset 0 -2px 0 $warning;
    }
  }
}