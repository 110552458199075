.form {
  width: 100%;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
}

.saveBtn {
  margin-top: 1.5rem;
}
