@import '../../styles/new/variables';

.pagination {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin: 23px 0;
}

.button {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 5px 14px;
  font-weight: 500;
}

.enabledButton {
  color: $tertiary;
}

.disabledButton {
  color: #9b9d9b
}

.button:hover {
  background-color: #d8d8d8;
  text-decoration: none;
}

.button:hover:disabled{
  background-color: #fff;
  color: #9b9d9b;
  cursor: not-allowed;
}
.results {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.hidden {
  display: none;
}

.siteJobResourceDisplay {
  background-color: #fff;
  margin: 0px;
  padding: 23px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  justify-content: space-between;
}