.static-google-map {
  height: 300px;
}

.static-google-map-jobs {
  height: 400px;
}

.google-controls {
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid transparent;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  height: 29px;
  margin-left: 17px;
  margin-top: 10px;
  outline: none;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  min-width: 50%;
}

.google-controls:focus {
  border-color: #4d90fe;
}


