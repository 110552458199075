.paymentFrame {
  display: flex;
  width: 100%;
  height: 300px;
  margin-top: 2rem;
  overflow: hidden;
}

.spinner{
  margin: 0 auto;
  height: 5rem;
}

.inputWrapper {
  width: 100%;
  display: block;
  > input {
    width: 100%;
  }
}