@import '../../styles/new/variables';

.detailsContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 25%;
}

.paymentContainer {
  align-items: center;
  padding: 1rem 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.paidMessaging {
  text-align: center;
  font-size: 16px;
}

.detailsTotal {
  font-size: 28px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  padding-top: 1rem;
  margin: 1rem 0;
  border-top: 1px solid $light-gray;
}

.noFeesMessage {
  text-align: center;
  margin-bottom: 2rem;
}
