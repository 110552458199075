@import '../../styles/new/variables';


.tabsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  width: 100%;
}

.activeTab {
  border-bottom: 2px solid $primary;
  font-weight: 500;
}

.navTab {
  font-size: 16px;
  flex-grow: 1;
  &:focus {
    outline: none !important; //overwriting bootstrap
  }
}

.inactiveTabContent {
  display: none;
}

.activeTabContent {
  display: block;
  width: 100%;
}
