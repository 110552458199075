@import '../../styles/new/variables';

.formContainer {
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 0 auto;
  padding-top: 1.5rem;
}

.checkboxDays {
  justify-content: space-between;
}

.formRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.rulesInputs > * {
  width: 33%;
  margin-right: 3rem;
  &:last-child {
    margin-right: 0;
  }
}

.dateInputs > * {
  width: 33%;
  &:first-child {
    margin-right: 3rem;
  }
}

.requestedTimeInputs > * {
  width: 50%;
  &:first-child {
    margin-right: 3rem;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 3rem;
}

.messageContainer {
  border: 1px solid $alert !important;
  padding: .75rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  margin: 2.25rem 0;
}

.messageContainerTitle {
  color: $alert;
  font-weight: 500;
  margin-right: .5rem;
}

.submitBtn {
  width: 17rem;
}