@import '../../../styles/new/variables';

.modal {
  background-color: $white;
}

.lowercase {
  text-transform: lowercase;
}

.btns {
  display: flex;
  justify-content: center;
  margin: 3rem 0;
}

.btn {
  margin: 0 1rem;
}
