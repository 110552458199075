
@import '../../styles/new/variables';

.newRequestType {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
}

.viewTicketLink {
  margin-left: .5rem;
  font-weight: normal !important;
  font-size: 1.5rem;
  line-height: 2rem;
}


.itemExit {
  opacity: 1;
}

.itemExitActive {
  opacity: 0;
  transition: opacity 1400ms ease-out 800ms;
}

.closeConfirmedButton {
  margin-left: auto;
  position: relative;
  top: 1rem;
}

.closeIcon {
  font-size: 1.75rem;
}

.clientName {
  line-height: .5;
  margin-bottom: .5rem;
}