.job-action-button {
  margin-left: 10px;
}

.phone-input {
  padding: 0 15px 0 0;
}

.phone-ext-input {
  padding: 0 0 0 15px;
}
