@import '../../../styles/new/variables';

.button-wrapper {
  display: flex;
  justify-content: center;
  & button:first-child {
    margin-right: 2rem;
  }
  margin-top: 2rem;
}

.job-search-table {
  margin-bottom: 1rem;

  th, td {
    padding: .25rem .5rem;
    width: 30%;
    line-height: 1.2;
  }

  th {
    vertical-align: bottom;
    padding-bottom: .75rem;
  }

  thead, tbody {
    border-bottom: 1px solid $med-dk-gray;
  }

  td {
    border-bottom: 1px solid $medium-gray;
  }
  
  .sm-cell {
    width: 5%;
  }
  
  .med-cell {
    width: 10%;
  }

  .lg-cell {
    width: 40%;
  }
}

.no-jobs-found {
  padding: 2rem 0;
  text-align: center;
}

button.no-jobs-found-cancel {
  padding: 1rem;
  display: block;
  margin: 0 0 0 auto;
}
