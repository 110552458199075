@import '../../styles/new/mixins';

.displayContainer {
  margin-top: 2.5rem;
  margin-right: -15px;
  margin-left: -15px;
}

.displayDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.billingDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.headerButtonWrapper {
  display: flex;
  margin-top:2rem;
  & > button {
    margin-left: 1rem;
  }
}


@include respond-to('lg') {
  .displayContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .displayDetails {
    width: 67%;
  }

  .billingDetails {
    width: 30%;
    margin-left: auto;
  }
}
