@import '../../styles/new/mixins';

.modal {
  width: 100%;
}

.header {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.headerBtns {
  margin: .5rem 0 0 0;
}

.importAcctsBtn {
  margin: .5rem 0 0 0;
}

.searchForm {
  margin-top: 3rem;
}

.newAcctBtn {
  margin: .5rem 0 0 1rem;
}

@include respond-to('sm') {
  margin: 2.5rem 0 0 0;
}
