@import '../../styles/new/mixins';

.form {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: .75rem 0;
}

.inputs {
  width: 100%;
}

.btns {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 1rem 0 0 0;
  width: 100%;
}

.btn {
  margin: .5rem 0 0 1rem;
}

@include respond-to('lg') {
  .form {
    flex-wrap: wrap;
  }

  .inputs {
    width: 50%;
  }

  .multipleInputs {
    & .inputs {
      align-items: center;
      display: flex;
      flex-wrap: no-wrap;
      justify-content: space-between;
      width: 70%;

      & input {
        margin-top: 1rem;
        width: 70%;
      }

      & label {
        width: 20%;
      }
    }
    & .btns {
      width: 30%;
    }
  }

  .btns {
    margin: 0;
    width: 50%;
  }

  .btn {
    margin: 0 0 0 1rem;
  }
}
