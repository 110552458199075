@import '../../../styles/new/variables';

.detailsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 1rem 1.75rem;
  border-radius: 4px;
}

.detailsEditItem {
  display: flex;
  flex-direction: row;
  flex-basis: 33%;
  color: $dark-gray;
  &:first-child, &:nth-child(2) {
    border-right: 1px solid $light-gray;
  }
  &:nth-child(2), &:last-child {
    padding-left: 1.75rem;
  }
  &:hover {
    color: $dark-gray;
    text-decoration: none;

  }
}

.detailsEditText {
  font-weight: 500;
  font-size: 16px;
  position: relative;
  top: .5rem;
}

.detailsEditIcon {
  font-size: 38px;
  margin-right: 1rem;
  position: relative;
  top: .75rem;
}

.detailType {
  font-size: 14px;
  font-weight: 500;
}

.calendarIcon {
  top: 1rem;
}

.detailsItem {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.detailsIcon {
  font-size: 20px;
  margin-top: .15rem;
  margin-right: .5rem;
}

.detailsInfo {
  margin-left: auto;
  font-size: 14px;
}

.actionText {
  outline: none;
  color: $tertiary;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
}

.exceptionTitle {
  font-weight: 500;
}

.exceptionButton {
  margin-left: auto;
  color: $tertiary;
  font-weight: 500;
}

.exceptionBody {
  line-height: 1.3;
  margin-top: .25rem;
  margin-left: .5rem;
}
