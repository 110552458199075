@import '../../../styles/new/variables';

// Specificity is high in this file in order to overwrite styles from Bootstrap.
input[type='number'].number {
  border: none;
  border-bottom: 1px solid $dark-gray;
  padding: .5rem;
  font-size: 1.4rem;
  text-align: center;
  &:focus {
    box-shadow: none;
    border-bottom: 2px solid $primary;
    outline: none;
  }
}

input[type="number"][disabled].number {
  border-bottom: 1px solid $light-gray;
  cursor: not-allowed;
}
