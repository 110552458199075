.pageTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.statusPanel {
  padding: 10px;
}

.spinner {
  margin: 0 auto;
}