@import '../../styles/new/mixins';
@import '../../styles/new/variables';

.refundContainer {
  width: 65%;
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.sectionTitle {
  background-color: $light-gray;
  padding: 1rem 2rem;
  margin: 0;
  font-size: 2rem;
}

.panelContainer {
  padding: 1rem 2rem;
  div {
    padding-top: 2rem;
  }
  legend {
    margin-bottom: 0;
    border-bottom: 0;
    font-size: 1.3rem;
    font-weight: bold;
  }
  label {
    display: block;
  }
  input {
    padding-right: .5rem;
  }
}

.refundAmountContainer {
  padding-top: 0;
  padding-left: 2.5rem;
  width: 50%;
}

.rightPanel {
  width: 30%;
}

.summary {
  width: 100%;
  th{
    font-weight: normal;
    &.summaryHeader {
      font-weight: bold;
    }
  }
  td {
    text-align: right;
    &.summaryInfo {
      color: $warning;
    }
  }
}

.refundError {
  border: 1px solid $warning;
  background-color: $warning-light;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem;
  margin-top: 2rem;
  div {
    padding: 0;
  }
  i {
    color: $warning;
    margin-right: 1rem;
  }
  p {
    margin: 0;
    font-size: 1.4rem;
    line-height: 2rem;
    color: $dark-gray;
  }
  .refundErrorHeader {
    font-size: 1.6rem;
    color: $warning;
    margin-bottom: 1rem;
  }
}

.paymentContainer {
  justify-content: space-between;
  margin-top: 4rem;
  button {
    margin-top: 2rem;
    display: flex;
    width: 100%;
    justify-content: center;
  }
}

@include respond-to('lg') {
  .paymentContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
}
