.modalContainer {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  width: 65%;
  position: relative;
  bottom: 20vh;
}

.modalHeader {
  align-self: center;
}

.modalExplanation {
  font-size: 16px;
  align-self: center;
  text-align: center;
}

.compareDescriptions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
}

.feeTypeSection {
  padding: 0 2rem;
  width: 50%;
}

.feeTypeName {
  font-weight: 500;
  margin: -.5rem 0 -.75rem 0;
}

.feeTypeForm {
  align-self: center;
}

.feeTypeFormLabel {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 16px;
  white-space: nowrap;
  
}

.radioInput {
  flex-basis: 5%;
}

.buttonContainer {
  text-align: center;
  margin-top: 2rem;
}

.continueButton {
  margin-left: 2rem;
}