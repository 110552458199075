@import '../../styles/new/variables';

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.customerOptions{
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll;
  margin-bottom: 2rem;
  > label {
    &:nth-child(odd) {
      background-color: $shadow;
    }
    display: flex;
    padding: .5rem;
    border-radius: 1rem;
    cursor: pointer;
    > input {
      margin-right: 2rem;
    }
    p {
      margin: 0;
      line-height: 1.5;
    }
  }
}
.submitButton {
  display: flex;
  justify-content: end;
}