@import '../../styles/new/_variables.scss';
.qboMessage {
  color: $warning;
  background-color: $light-gray;
  padding: .75rem 1rem;
  margin: 1.25rem 1.5rem 1.5rem 1.5rem;
}

.formSection {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.checkboxContainer {
  flex-basis: 25%;
}

.inputContainer {
  margin-left: 8.333%;
  flex-basis: 66.66667%;
}

.timeContainer {
  flex-basis: 50%;
  &:first-child {
    margin-right: 8.333%;
  }
}

.btnContainer {
  display: flex;
  margin-top: 2rem;
  justify-content: flex-end;
}

.messageContainer {
  border: 1px solid $alert !important;
  padding: .75rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  margin: 2.25rem 0;
}

.messageContainerTitle {
  color: $alert;
  font-weight: 500;
  margin-right: .5rem;
}