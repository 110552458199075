@import '../../styles/new/variables';

.listItem {
  background-color: $white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  font-weight: 700;
  margin: 0.5rem 0;
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.description {
  margin: 0.5rem 0;
}

.btnLink {
  color: $dark-gray;
  align-self: center;
  &:hover {
    color: $dark-gray !important;
    text-decoration: none !important;
    background-color: $primary-lt !important;
   }
}

.mdText {
  font-size: 1.5rem;
  line-height: 2rem;
}

.fontMedium {
  font-weight: 500;
}

.selectWidth {
  width: 24rem; 
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}