@import '../../styles/new/variables';

.paymentDetails, .backToTicketBtnContainer {
  text-align: center;
}

.receiptBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.receiptContainer {
  text-align: center;
}

.paymentSuccess {
  color: $primary;
  margin: 0 0 11.5px 0;
}

.paymentTotal {
  font-weight: 500;
}

.qboMessage {
  font-size: 16px;
  color: $med-dk-gray;
  margin-bottom: .5rem;
}

.tableContainer {
  border-top: 1px solid $light-gray;
  margin: 3rem 0;
}

.backToTicketBtnManual {
  margin-top: 2rem;
  padding-bottom: 2rem;
}

.paymentStatus {
  margin-bottom: 2rem;
}

.tableSeparator {
  margin: 4rem 0 1rem 0;
  border-color: $light-gray;
}

.panelBody {
  padding: 3.8rem 1.5rem;
}

.emails {
  margin: 3rem 0 0 0;
}
