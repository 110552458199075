@import '../../../styles/new/variables';

.activitiesButton {
  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.activeButton {
  z-index: 2;
  background-color: #e6e6e6 !important;
  box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, .13) !important;
  border-color: transparent;
  &:hover {
    background-color: #d4d4d4;
  }
}

.inactiveButton {
  border-radius: 3px;
  box-shadow: none;
  background-color: $white;
  &:hover {
    background-color: $light-gray;
  }
}

.panelFooter {
  padding: .75rem 1.5rem;
}

.activitiesHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.activitiesItemRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.noComments {
  color: $med-dk-gray;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  font-size: 16px;
}

.noCommentsText {
  width: 100%;
}

.addCommentBtn {
  margin: 1rem auto auto auto;
}

.addBtnContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.activityFormItem {
  margin-bottom: 2rem;
}

.activityForm {
  padding: .25rem;
}

.activityFormBtns {
  display: flex;
  justify-content: flex-end;
}
