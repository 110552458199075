.description {
  font-size: 1.4rem;
  margin: 1rem 1.5rem 0 1.5rem;
  max-width: 345px;
}

.button {
  margin: 2rem 0 0 0;
}

.wpImgContainer {
  height: 37px;
  margin-left: auto;
  opacity: .4;
  width: 134px;
  margin-top: 2rem;
}
