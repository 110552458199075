@import '../../styles/new/mixins';
@import '../../styles/new/variables';

.invoice {
  margin-top: 4rem;
}

.previewContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 65%;
}

.sendInvoice {
  border-color: $dark-gray;
  border-top: 1px solid $light-gray;
  padding: 1rem 2rem 2rem 2rem;
}

.actions {
  flex-basis: 35%;
}

.payAccordion {
  margin: 1rem 0 0 0;
}

.editInvoiceBtn {
  width: fit-content;
  margin: 2rem 0;
}

.editInvoiceIcon {
  margin-top: -5px;
}

.exportToQBOBtn {
  font-weight: 500;
}

.wpImgContainer {
  width: 134px;
  height: 37px;
  margin-left: auto;
}

.wpImgContainer > img {
  width: 100%;
  height: auto;
}

@include respond-to('lg') {
  .invoice {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .editInvoiceBtn {
    width: fit-content;
    margin: 0;
  }
}

.panel {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 0 2rem;
  text-align: center;
}

.header {
  border-bottom: 1px solid rgba(231,233,237, 1);
  display: flex;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  justify-content: space-between;
  letter-spacing: 0px;
  width: 100%;
}

h4 {
  padding: 0 2rem;
}

h4 + span {
  padding: 1.2rem;
}

.title {
  font-size: 2.5rem;
  margin: 0;
}

.closeOutQbo {
  display: flex;
  flex-direction: column;
  margin: 1rem 2rem;
}

.closeOutQboBtn {
  justify-content: center;
}

.bold {
  font-weight: bold;
}

