#breadcrumbs {

  margin-left: -3.5rem;

  a {
    font-weight: 100;
    color: $white;
    padding-right: 10px;
  }
}

.breadcrumbs-span {
  padding-right: 10px;
}
