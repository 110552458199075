.title {
  margin: 23px 0 0 20px;
  font-size: 4rem;
}

.description {
  margin: 5px 0 0 20px;
}

.reports {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
}

.reportItem {
  margin: 10px 0 10px 0;
  width: 100%;
}

.reportCard {
  background-color: white;
  border: 1px solid #D8D8D8;
  border-radius: 2px;
  display: flex;
  height: 100%;
  margin: 20px 20px;
  padding: 20px 15px;
}

.reportCardBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20px;
  width: 60%;
}

.reportCardImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 40%;
}

.reportCardImage {
  width: 100%;
}

.reportCardTitle {
  color: #131514;
  font-size: 2rem;
  margin: 0;
}

.reportCardDescription {
  color: #434A54;
  font-size: 1.5rem;
  line-height: 1.5;
  margin: 8px 0 0 0;
}

.reportCardButton {
  background-color: #33CA80;
  border-radius: 2px;
  color: black;
  display: inline-block;
  padding: 2px 15px;
  font-size: 1.5rem;
  margin-top: 20px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}

.reportCardButton:hover {
  box-shadow: 1px 1px 2px #888888;
  color: black;
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  .reportItem {
    width: 50%;
  }
}

@media only screen and (min-width: 1030px) {
  .reportItem {
    width: 33.33%;
  }
}
