@import '../../styles/new/variables';

.displayAddressContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-basis: 100%;
}

.displayAddressInput {
  flex-basis: 65%;
}

.addressFields {
  flex-basis: 35%;
  margin: auto 0 20px 20px;
  font-size: 16px;
  color: $medium-gray;
  line-height: 1.846;
}