@import '../../styles/new/variables';
@import '../../styles/new/mixins';

.totalContainer {
  margin-left: auto;
  font-size: 16px;
  display: table;
}

.totalContainer > div {
  display: table-row;
}

.totalLine {
  padding: 0 8px;
}

.totalLine, .totalLineTax {
  display: table-cell;
  text-align: right;
}

.totalLineTax {
  padding: 0 8px 1rem 8px;
}


.total, .outstandingBalance {
  font-weight: 500;
  font-size: 18px;
  display: table-cell;
  padding: 0 8px;
  text-align: right;
  border-collapse: collapse;
  padding-top: 1rem;
}

.total {
  border-top: 1px solid $dark-gray;
}

.qboTaxDisclaimer {
  color: $medium-gray;
  font-style: italic;
  width: 60%;
  position: relative;
  align-self: flex-start;
  top: -3rem;
}
